<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            color="cyan"
            @keydown.enter="getAllQuotations"
            @keydown.tab="getAllQuotations"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <DateRangePicker
            placeholder="Data Range"
            v-model="dates"
            v-on:update:model-value="getAllQuotations()"
            v-on:click:clear="clearFilter('dates')"
          ></DateRangePicker>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable :columnCount="6" :rowData="dataRows2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Opportunity #</th>
            <th class="simple-table-th" width="100">Opportunity</th>
            <th class="simple-table-th" width="300">Amount</th>
            <th class="simple-table-th" width="200">Company</th>
            <th class="simple-table-th" width="300">Site Location</th>
            <th class="simple-table-th" width="200">Status</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows2.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows2"
              v-on:click="
                $router.push(
                  getDefaultRoute('quotation.detail', {
                    params: { id: row.id },
                  })
                )
              "
              class="alternate-listing-row"
            >
              <td class="simple-table-td">
                <Barcode :barcode="'Op-002155'"></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ row.name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference #: </b>
                    <template v-if="row.reference">
                      {{ row.reference }}
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Reference </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Start Date: </b>
                    {{ formatDate(row.started_at) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>End Date: </b> {{ formatDate(row.deadline) }}
                  </p>
                </div>
              </td>

              <td class="simple-table-td" width="100">
                <div class="project-listing-project">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Opportunity Value: </b>
                    <template v-if="row && row.project_value"
                      ><span class="font-weight-500">{{
                        formatMoney(row.project_value)
                      }}</span></template
                    >
                    <template v-else
                      ><em class="text--secondary">No Opportunity </em>
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Opportunity Budget: </b>
                    <template v-if="row && row.project_budget"
                      ><span class="font-weight-500">{{
                        formatMoney(row.project_budget)
                      }}</span></template
                    >
                    <template v-else
                      ><em class="text--secondary">No Budget </em>
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="project-listing-project" v-if="row.customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b>
                    {{ row.customer && row.customer.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b>
                    <template v-if="row.customer && row.customer.company_name">
                      {{ row.customer && row.customer.company_name }}
                    </template>
                    <em v-else class="text--secondary">no company</em>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b> Email : </b>
                    <template v-if="row.customer && row.customer.company_email">
                      {{ row.customer.company_email }}</template
                    >
                    <em v-else class="text--secondary">no email</em>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Phone Number : </b>
                    <template
                      v-if="row.customer && row.customer.company_number"
                    >
                      {{ row.customer.company_number }}</template
                    >
                    <em v-else class="text--secondary">no phone</em>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <p
                  v-if="row.property_person"
                  class="m-0 custom-nowrap-ellipsis"
                >
                  <v-icon small left>mdi-account</v-icon>
                  {{ row.property_person && row.property_person.display_name }}
                </p>
                <p
                  v-if="row.property_person"
                  class="m-0 custom-nowrap-ellipsis"
                >
                  <v-icon small left>mdi-email</v-icon>
                  <template
                    v-if="
                      row.property_person && row.property_person.primary_email
                    "
                  >
                    {{ row.property_person.primary_email }}</template
                  >
                  <em v-else class="text--secondary">no email</em>

                  <!--   {{
                              row.property_person &&
                              row.property_person.primary_email
                            }} -->
                </p>
                <p
                  v-if="row.property_person"
                  class="m-0 custom-nowrap-ellipsis"
                >
                  <v-icon small left>mdi-phone</v-icon>
                  <template
                    v-if="
                      row.property_person && row.property_person.primary_phone
                    "
                  >
                    {{ row.property_person.primary_phone }}</template
                  >
                  <em v-else class="text--secondary">no phone</em>
                  <!--   {{
                              row.property_person &&
                              row.property_person.primary_phone
                            }} -->
                </p>
                <p v-if="row.property" class="m-0 custom-nowrap-ellipsis">
                  <v-icon size="16" class="mr-2">mdi-map-marker</v-icon>
                  <span style="display: inline-flex !important">
                    {{ row?.property?.property_name }}
                    {{ row.property.street_1 }}
                    {{ row.property.street_2 }}
                    <br />
                    {{ row.property.unit_no }}
                    {{ row.property.zip_code }}
                    {{ row.property.country }}</span
                  >
                </p>
              </td>

              <td class="simple-table-td" width="100">
                <CustomStatus
                  small
                  :status="row.status"
                  endpoint="quotation/status"
                ></CustomStatus>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no quotation at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import JwtService from "@/core/services/jwt.service";
import DateRangePicker from "@/view/components/DateRangePicker";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data() {
    return {
      parent: 0,
      customer: 0,
      userDetails: {},
      customerPerson: 0,
      customerBilling: 0,
      dates: [],
      statusList: [],
      dataRows2: [
        {
          id: 2,
          name: "fss",
          barcode: "OP000002",
          attention: null,
          reference: null,
          soled_job_no: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            profile_logo: null,
          },
          lead: "0",
          proposal: "0",
          quotation: "0",
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: null,
          project_value: "1456",
          project_budget: "545",
          property_contact_person: "1",
          billing_contact_person: "1",
          cost: "0.00",
          rate_per_hour: "0.00",
          estimated_hour: "0.00",
          billing_type: "1",
          started_at: "2024-08-24",
          deadline: "2024-09-24",
          finished_at: null,
          progress: "0",
          status: 1,
          added_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          added_at: "2024-08-24 11:48:19",
          updated_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          updated_at: "2024-08-24 11:48:19",
          opne_visit_count: "0",
          overdue_visit_count: "0",
          completed_visit_count: "0",
          inprogress_visit_count: "0",
          created_at: "1 week ago",
          modified_at: "1 week ago",
          billing_type_text: "Fixed Rate",
          status_text: "Not Started",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "Op-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          milestone: [],
        },
        {
          id: 1,
          name: "Project Nam",
          barcode: "OP-000001",
          attention: null,
          reference: null,
          soled_job_no: null,
          customer: {
            id: 1,
            barcode: "C-000004",
            display_name: "Business Thrust Techsoft Pvt Ltd.",
            company_name: "Business Thrust Techsoft Pvt Ltd.",
            company_email: null,
            company_number: null,
            profile_logo: null,
          },
          lead: "0",
          proposal: "0",
          quotation: "0",
          property: {
            id: 1,
            barcode: "P-000008",
            property_name: null,
            customer: 1,
            supplier: "0",
            contact_person: 1,
            is_same: "1",
            is_tenant: 0,
            unit_no: "#01-583",
            street_1: "ATM, Bendemeer Road Branch (UOB)",
            street_2: "25 Bendemeer Road",
            zip_code: "330025",
            country: "0",
            zone_territory: null,
            state: "0",
            city: "0",
            latitude: null,
            longitude: null,
            remarks: null,
            entity: "Business Thrust Pte Ltd.",
            entity_id: "1",
            type: 1,
            activated: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            property_address:
              "ATM, Bendemeer Road Branch (UOB) 25 Bendemeer Road #01-583 330025",
          },
          billing: null,
          project_value: "100000",
          project_budget: "90",
          property_contact_person: "1",
          billing_contact_person: "1",
          cost: "0.00",
          rate_per_hour: "0.00",
          estimated_hour: "0.00",
          billing_type: "1",
          started_at: "2024-08-24",
          deadline: "2024-09-24",
          finished_at: null,
          progress: "0",
          status: 1,
          added_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          added_at: "2024-08-24 11:44:58",
          updated_by: {
            id: 1,
            display_name: "Admin",
            title: "Mr.",
            first_name: "Super",
            last_name: "Admin",
            user_email: "admin@bthrust.com",
            full_name: "Mr. Super Admin",
          },
          updated_at: "2024-08-24 11:44:58",
          opne_visit_count: "0",
          overdue_visit_count: "0",
          completed_visit_count: "0",
          inprogress_visit_count: "0",
          created_at: "1 week ago",
          modified_at: "1 week ago",
          billing_type_text: "Fixed Rate",
          status_text: "Not Started",
          property_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          billing_person: {
            id: 1,
            uuid: null,
            barcode: "CP-00003",
            customer: "1",
            title: "mr",
            first_name: "Suraj",
            last_name: "Prasad",
            display_name: "Suraj Prasad",
            primary_email: "suraj@businessthrust.com",
            primary_phone: "+65-12345678",
            position: null,
            tenant: 0,
            default: true,
            activated: true,
            will_notified: true,
            added_by: "1",
            added_at: "2024-08-24 09:56:03",
            updated_by: "0",
            updated_at: null,
            did: null,
            designation: null,
            full_name: "Mr. Suraj Prasad",
          },
          milestone: [],
        },
      ],
      dataLoading: true,
      customerPersonDialog: false,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }
          /* this.getAllQuotations(); */
        }
      },
    },
  },
  components: {
    Barcode,
    CustomerPersonDialog,
    ListingTable,
    CustomStatus,
    DateRangePicker,
  },
  methods: {
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        let queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling,
        });

        queryObject[_this.type] = _this.parent;

        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: queryObject,
          })
        );
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllQuotations();
    },
    getAllQuotations() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "quotation",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.userDetails = JwtService.currentUser();
  },
};
</script>
