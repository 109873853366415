<template>
  <!--begin::Project Costing Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2" class="pl-0">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            color="cyan"
            @keydown.enter="getAllProjectCostings"
            @keydown.tab="getAllProjectCostings"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                v-on:click="getAllProjectCostings"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 color-custom-blue white--text"
                color="cyan"
                tile
                v-on:click="
                  $router.push(
                    getDefaultRoute('costing.create', {
                      query: { project: parent },
                    })
                  )
                "
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create New Project Costing</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable :columnCount="8" :rowData="dataRows2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Costing #</th>
            <th class="simple-table-th" width="300">Title</th>
            <th class="simple-table-th" width="100">Opportunity Title</th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="200">Company</th>
            <th class="simple-table-th" width="100">Dates</th>
            <th class="simple-table-th" width="100">Status</th>
            <th class="simple-table-th" width="200">Amount</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows2.length > 0">
            <tr link :key="index" v-for="(row, index) in dataRows2">
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.quotation"
                >
                  <Barcode :barcode="row.quotation.barcode"></Barcode>
                </div>
                <div v-on:click.stop>
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!row.pdf_url"
                        color="cyan"
                        v-on:click.stop.prevent="downloadPdf(row)"
                        icon
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Download PDF</span>
                  </v-tooltip>
                </div>
              </td>
              <td class="simple-table-td" width="200">
                <div class="projectcosting-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <template v-if="row.quotation.job_title">{{
                      row.quotation.job_title
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Job Title</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="projectcosting-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis d-flex">
                    <b> Opportunity: </b>
                    <template v-if="row.opportunity_title">{{
                      row.opportunity_title
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Opportunity title</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis d-flex">
                    <b> Project: </b>
                    <template v-if="row.project_title">{{
                      row.project_title
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Project title</em
                      ></template
                    >
                  </p>
                </div>
              </td>

              <td class="simple-table-td" width="100">
                <div class="">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sales representative: </b>
                    <template v-if="row.sales_user">
                      <v-chip
                        v-if="
                          row && row.sales_user && row.sales_user.display_name
                        "
                        small
                        class="mb-1 mr-1"
                      >
                        <v-avatar left small>
                          <img
                            v-if="
                              row.sales_user &&
                              row.sales_user.profile_logo &&
                              row.sales_user.profile_logo.file
                            "
                            :src="row.sales_user.profile_logo.file.url"
                          />
                          <img
                            v-else
                            src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                          />
                        </v-avatar>
                        <span>{{ row.sales_user.display_name }}</span>
                      </v-chip>
                    </template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Sales representative</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Prepared by: </b>
                    <template
                      v-if="row.prepared_user && row.prepared_user.display_name"
                      >{{ row.prepared_by }}
                      <v-chip
                        v-if="
                          row &&
                          row.prepared_user &&
                          row.prepared_user.display_name
                        "
                        small
                        class="mb-1 mr-1"
                      >
                        <v-avatar left small>
                          <img
                            v-if="
                              row.prepared_user &&
                              row.prepared_user.profile_logo &&
                              row.prepared_user.profile_logo.file
                            "
                            :src="row.prepared_user.profile_logo.file.url"
                          />
                          <img
                            v-else
                            src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                          />
                        </v-avatar>
                        <span>{{ row.prepared_user.display_name }}</span>
                      </v-chip>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Prepared by</em></template
                    >
                  </p>

                  <p class="m-0 custom-nowrap-ellipsis d-flex">
                    <b> Reports: </b>
                    <template v-if="row.rating">{{ row.reports }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reports</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis d-flex">
                    <b> Rating: </b>
                    <template v-if="!row.rating"
                      ><v-rating
                        readonly
                        dense
                        v-model.trim="row.rating"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating
                    ></template>
                    <template v-else
                      ><em class="text--secondary">No Rating</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.customer"
                >
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b>
                    {{ row.customer.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b> {{ row.customer.company_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Email: </b>
                    {{ row.customer.default_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.default_person"
                  >
                    <b>Phone: </b>
                    {{ row.customer.default_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.added_at">
                  <b>Created: </b> {{ formatDate(row.added_at) }}
                </p>
                <p
                  class="m-0 custom-nowrap-ellipsis"
                  v-if="row.validity_till && row.validity_till > 0"
                >
                  <b>Validity: </b> {{ getValidityDate(row) }}({{
                    row.validity_till
                  }}Days)
                </p>
                <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                  <b>Sent: </b> {{ formatDate(row.sent_date) }}
                </p>
              </td>
              <td class="simple-table-td" width="100">
                <CustomStatus
                  small
                  :status="row.status"
                  endpoint="quotation/status"
                ></CustomStatus>
                <div class="mb-1">
                  <template
                    v-if="row.pending_for_approver == 1 && row.status == 1"
                  >
                    <v-chip
                      color="red"
                      text-color="white"
                      label
                      small
                      class="p-3 ml-2 mb-1"
                    >
                      {{ row.pending_approver }}
                    </v-chip>
                  </template>
                </div>
                <div class="mb-1">
                  <v-chip
                    v-if="
                      row &&
                      row.quotation_duplicate &&
                      row.quotation_duplicate > 0
                    "
                    label
                    small
                    color="red"
                    outlined
                    class="text-white p-3 ml-2 mb-1"
                    >Duplicate</v-chip
                  >
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="costing-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Selling: </b>
                    <span class="font-weight-700">
                      {{ $accountingJS.formatMoney(row.total_selling) }}</span
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Cost Value : </b>
                    {{ row.total_cost_value }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total Margin : </b>
                    {{ row.total_margin }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis font-weight-bold"
                    style="font-size: 15px"
                  >
                    <b>Grand Total : </b>
                    <span class="font-weight-bold">
                      2345.00
                      <!-- {{
                                  $accountingJS.formatMoney(row.total)
                                }} -->
                    </span>
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="8">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no project costing at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Project Costing Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
export default {
  mixins: [CommonMixin],
  name: "project-costing-internal-list",
  data() {
    return {
      parent: 0,
      dataRows2: [
        {
          barcode: "COST-00013",
          q_barcode: "QT-00011",
          soled_job_no: "",
          customer: {
            display_name: "Abc pvt lte",
            company_name: "Abc pvt lte",
            default_person: {
              primary_email: "john@gmail.com",
              primary_phone: "+65 1234 5678",
            },
          },
          quotation: {
            barcode: "QT-000012",
            job_title: "title",
          },
          added_at: "24/08/2024",
          validity_till: "24/08/2024",
          sent_date: "24/08/2024",
          total_selling: 12900.0,
          total_cost_value: 12900.0,
          total_margin: 2900.0,
        },
        {
          barcode: "COST-00013",
          q_barcode: "QT-00010",
          soled_job_no: "",
          customer_po_no: "",
          customer_po_date: "",
          customer: {
            company_name: "Abc pvt lte",
            default_person: {
              primary_email: "john@gmail.com",
              primary_phone: "+65 1234 5678",
            },
          },
          quotation: {
            barcode: "QT-000012",
            job_title: "title",
          },
          added_at: "24/08/2024",
          validity_till: "24/08/2024",
          sent_date: "24/08/2024",
          total_selling: 12900.0,
          total_cost_value: 12900.0,
          total_margin: 2900.0,
        },
      ],
      dataLoading: true,
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllProjectCostings();
        }
      },
    },
  },
  components: {
    Barcode,
    ListingTable,
    CustomStatus,
  },
  methods: {
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    downloadPdf(row) {
      window.open(
        process.env.VUE_APP_API_URL + "project/costing/" + row.id + "/download",
        "_blank"
      );
    },
    pushToQuotation(quotation) {
      this.$router.push(
        this.getDefaultRoute("quotation.detail", {
          params: {
            id: quotation.id,
          },
        })
      );
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllProjectCostings();
    },
    getAllProjectCostings() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        search: _this.filter.search || undefined,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "project/costing",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
